@use 'sass:math';

$primary: slategray;
$secondary: white;
$accent: darkorange;

// change the theme
$theme-colors: (
  "primary": $primary,
  "danger": $secondary,
  "warning": $accent,
  --bs-modal-color: slategray
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

html, body {
  height: 100%; /* Ensure the root elements fill the viewport */
  margin: 0; /* Remove default margins */
}

.app{
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  background-color: black;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid ivory;
  padding: 16px 0;
}

.header-text{
  color: rebeccapurple;
  font-size: 30px;
  // font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  letter-spacing: 4px;
  font-weight: 900;
}

.header-subtext{
  color: ivory;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2px;
}

main {
  padding: 16px 0;
  background-color: ivory;
  flex-grow: 1;
  // border: 2px solid ivory;
  overflow-y: auto; /* Makes the main scrollable if content exceeds height */
}

footer {
  color: ivory;
  background-color: black;
  border-top: 2px solid ivory;
  padding: 16px 0;
}

h1{
  font-size: 24px;
  font-weight: 600;
  font-family: 'Courier New', Courier, monospace;
}